import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import CustomHeader from "../../components/header";
import './multiProjectDetails.scss';
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import socketIOClient from "socket.io-client";
import { FaCheckCircle } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import url from "../../config.js";
import Chart from "../../components/chart";
import {MultiChart} from "../../components/chart";
import StatusIcon from "../../components/statusIcon";
import progressIcon from "../../assets/icons/processing-unscreen.gif"
import completedIcon from "../../assets/icons/check-circle-fill.svg"
import { AddNewInput, SubmitButton, ValidationComponent } from "../../components/new-resource-form/index.js"
import * as myConst from "../../constants/configurationPageConstants";
import healthapiled from '../../assets/icons/health-apiled.png';


const cloudHubDeployList = myConst.cloudHubDeployList;
const cloudHub2DeployList = myConst.cloudHub2DeployList;


const domain = url.domain;
const ENDPOINT = url.ENDPOINT;
const socketEndpoint = url.SOCKET;
const multipleProjectTemplate = ["salesforce-sapi", "case-listener", "case-papi", "-jira-eapi", "-salesforce-topic-listener", "-servicenow-sapi"];
const educationProjectTemplate = ["itrent-sapi", "sits-sapi", "agresso-sapi", 'employee-papi', 'salesforce-sapi', 'student-papi', 'platform-eapi', 'employee-papi']
// const eduPjtSapi =["itrent-sapi", "sits-sapi", "agresso-sapi",'salesforce-sapi']
// const eduPjtPapi =[ 'employee-papi', 'student-papi']
// const eduPjtEapi=['platform-eapi']
const eduPjtSapi =["sec-sapi", "stripe-sapi", "okta-sapi",'moodle-sapi']
const eduPjtPapi =[ 'sec-papi']
const eduPjtEapi=['enrolment-eapi']
const openFinProjectTemplate = ["service-cloud-sapi","docusign-sapi","s3-sapi","document-envelope-sapi","fin-cloud-sapi","kyc-sapi","salesforce-sapi","rds-sapi","client-papi","salesforce-eapi","acc-valn-papi","client-mobile-eapi"]
const openFinProjectEapi = ["salesforce-eapi","client-mobile-eapi"]
const openFinProjectPapi = ["client-papi","acc-valn-papi"]
const openFinProjectSapi =  ["docusign-sapi","s3-sapi","experiankyc-sapi","salesforce-sapi","rds-sapi","yodlee-sapi"]
const wsProjectSapi =  ["conga-sapi","content-hub-sapi","transactis-sapi"]
const openBankingPjtSapi =["rds-sapi", "keycloak-sapi"]
const openBankingPjtPapi =[ "consent-mgmt-papi"]
const openBankingPjtEapi=['ais-eapi',"consent-mgmt-eapi","fps-api"]
const generalPjtPapi =[ "general-papi"]
const generalPjtEapi=['general-eapi']





var update = require('immutability-helper');
export default function MultiProjectDetails(props) {
  const openFinPjtData=[]
  let { projId,projName } = useParams();
  console.log("ProjectName",projName);
  const navigate = useNavigate()
  const [state, setState] = useState({
    resources: [],
    resource_group_name: [],
    azure_container: [],
    azure_cluster: [],
    projects: null,
    startedDeployment: false,
    statusTempSelect: false,
    projTempSelect:'',
    // projTempStatus: [],

    azStatus: null,
    activePage: '',
    showModal: false,
    projectStep: 1,
    akcontainer: [],
    github: [],
    projectStatus: [],
    readyToDeploy: false,
    myclass: "",
    consoleValue: "",
    deployedUrl: "",
    buildStarted: false,
    buildFinished: false,
    consoleMessage: "",
    deployed: false,
    isreleaseEnabled: false,
    validationMessage: "",
    validationError: false,
    deployType: "",
    availableRTF: [],
    aksClusterResouce: [],
    targetCluster: '',
    targetRTF: '',
    workersNumber: '',
    workerType: '',
    workerSize: '',
    environment:''
 

  })
  const [consoleMessages, setConsoleMessages] = useState('');
  const [projectStatus, setProjectStatus] = useState([]);
  const [projectName, setProjectName] = useState('');
  function onChange(event) {
    console.log("event", event)
    event.persist()

    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }
  const resetState = () => {
    setState(prevState => ({ ...prevState, startedDeployment: false }));
    setState(prevState => ({ ...prevState, buildStarted: false }));
    setState(prevState => ({ ...prevState, buildFinished: false }));
    setState(prevState => ({ ...prevState, buildComplete: false }));
  }
  const showContainer = () => {
    if (state.myclass === '') {
      setState(prevState => ({ ...prevState, myclass: 'slide' }))
    }
    else {
      setState(prevState => ({ ...prevState, myclass: '' }))
    }
  }
function getProjectStatus(template){
 
  // const statusData = (projectStatus !== undefined && projectStatus.length !== 0)? projectStatus.filter(status => {

  //   return status.exactPjtName === template;
  // }): [];
  setState( prevState => ({ ...prevState, projTempSelect: template,
    statusTempSelect: true}));

}

  function getPipeLineStatus(project) {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/start/pipeline?projectName=' + project, requestOptions)
      .then(response => response.json()
      )
      .then(data => {
        console.log(data);
        if (data.message === 'Build Exists') {
          setState(prevState => ({ ...prevState, buildStarted: true }))
          setState(prevState => ({ ...prevState, readyToDeploy: false }))
        } else {
          resetState();
          setState(prevState => ({ ...prevState, readyToDeploy: true }))
        }

      });
  }
  function getProject() {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/project', requestOptions)
      .then(response => response.json()
      )
      .then(data => {
        console.log(data);
        data.projects.map(pro => {
          if (pro._id === projId) {
            setState(prevState => ({ ...prevState, projects: pro }))
            setProjectName(pro.projectName)
            if (pro.buildComplete == true || pro.buildComplete === "true") {
              // this.setState({ readyToDeploy: true })
              getPipeLineStatus(pro.projectName);
            }
          }

        })
      });

    if (state.projects && state.projects.startedBuild) {

      const interval = setInterval(() => {
        if (state.projects.finishedBuild) {
          clearInterval(interval)
        } else {
          getAzureStatus()
        }
      }, 1000);
    }
  }
  function getResource() {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/azresource', requestOptions)
      .then(response => response.json())
      .then(data => {
        setState(prevState => ({ ...prevState, resources: data }));
        if (data && data.length) {
          setState(prevState => ({ ...prevState, aksClusterResouce: data }))
          data.map((res) => {
            console.log("Azure Resources");
            console.log(res);
            var resources = state.resource_group_name.concat(res.resource_group_name);
            setState(prevState => ({ ...prevState, resource_group_name: resources }))
            console.log(state.resource_group_name);
            setState(prevState => ({ ...prevState, azureResourceAvailable: true }))
          });
        }

      });
  }
  function getGithub() {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/repo', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log("GIT DATA",data)
        if (data.length) {

          setState(prevState => ({ ...prevState, github: data }));
          setState(prevState => ({ ...prevState, gitReposAvailable: true }))

        }
        console.log("gitAccount", state.github)
      });
  
    
  }
  const getAzureStatus = () => {
    console.log('inisde status call');
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/azstatus?id=' + projId, requestOptions)
      .then(response => response.json()
      )
      .then(data => {
        setState(prevState => ({ ...prevState, azStatus: data[0] }))
      });
  }
  // const createRelease = () => {
  //   setContent({ consoleMessage: 'Release in progress' })
  //   var postObj =
  //   {
  //     "id": projId,
  //     "env": [
  //       {
  //         "clientId": state.sandBoxClientId,
  //         "clientSecret": state.sandBoxClientSecret,
  //         "name": state.projects.projectName + '-Sandbox' + state.projects.projectType,
  //         "env": "dev",
  //         "username": state.muleUsername,
  //         "password": state.mulePassword
  //       },
  //       {
  //         "clientId": state.qaClientId,
  //         "clientSecret": state.qaClientSecret,
  //         "name": state.projects.projectName + '-QA' + state.projects.projectType,
  //         "env": "test",
  //         "username": state.qaUsername,
  //         "password": state.qaPassword
  //       }
  //     ]
  //   }
  //   console.log(postObj);
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
  //     body: JSON.stringify(postObj)
  //   };
  //   fetch(domain + '/api/v1/createrelease?id=' + projId, requestOptions)
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log(data);
  //     });
  // }
  const gotoproject = (id) => {
    navigate('/project/' + id);
  }
  const getProjectImage = (type) => {
    let url = ""
    if (type === 'react') {
      url = "../../assets/icons/react.png"
    }
    if (type === 'spring') {
      url = "../../assets/icons/spring.png"
    }
    return url;
  }
  // setLocalStorage() {
  //   if (this.state.projectStatus && this.state.projectStatus.length) {
  //     localStorage.setItem(this.projId + '-build', JSON.stringify(this.state.projectStatus))
  //   }
  // }
  // getBuildFromLocalStorage() {
  //   let buildStatus = JSON.parse(localStorage.getItem(this.projId + '-build'));
  //   this.setState({ projectStatus: buildStatus });
  // }
  useEffect(() => {
    // this.getBuildFromLocalStorage();
    getResource();
    getProject();
    getGithub();
    getJenkins();
    getMule();
    getBitbucket();
    getRTFResource();
    //var url = "//apiops.njclabs.com:" + ( window.location.protocol === "https:" ? "3334" : "8083" );
    // var url = "//localhost:" + (window.location.protocol === "https:" ? "3334" : "8083" );
    // var url = "https://188.166.168.44:443/socket.io";
     

    const socket = socketIOClient(url, { rejectUnauthorized: true });
    console.log("URLLLLLLLLLLLLL",url)
    // const socket = socketIOClient("http://localhost:8083", { rejectUnauthorized: true });
    console.log("socket project details",socket)

    

    socket.on("connect", function (e) {
      console.log("connect", e);
      console.log("STATE project", state.projects);

    });

    
    socket.on("releaseupdate", data => {
      console.log("RELEASE",data);
      if (data.status == "done") {
        resetState();
        setState({ deployed: true });
      }
      let message = state.consoleValue + '\n' + data.message + '\n';
      setState(prevState => ({
        ...prevState,
        consoleValue: message
      }))
      if (data.status !== 'console') {
        setState(prevState => ({
          ...prevState,
          projectStatus: [...state.projectStatus, data]
        }))
      }
    })
    socket.on("deployment", data => {
      console.log(data);
      if (data.status == "done") {
        setState(prevState => ({ ...prevState, deployedUrl: data.message }));
        resetState();
        setState(prevState => ({ ...prevState, deployed: true }));
      }
      let message = state.consoleValue + '\n' + data.message + '\n';
      setState(prevState => ({ ...prevState, consoleValue: message }))
    })
    socket.on("buildupdate", data => {

      console.log(data);
      console.log("projName",projName,"Data Proj",data.projectName);

      if (data && data.projectName === projName){
          if (data && data.status === 'console') {
          console.log(consoleMessages);
          setConsoleMessages((consoleMessages) => {
            console.log(projectName);
            return [...new Set(consoleMessages), data.message]
          })
        } else {
          setProjectStatus((projectStatus) => {
            console.log(projectName);
            console.log("projectStatus",projectStatus);
            console.log("data",data);


            const targetIndex = projectStatus.findIndex(item => item.message === data.message);
            console.log("index",targetIndex);
            if (targetIndex !== -1) {
              projectStatus.splice(targetIndex, 1);
            }
            console.log("status after splice",projectStatus);
            return [...new Set(projectStatus), data]
          })
        }
      }
    });
    
    

  }, [])
  const getAzResource = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/azresource', requestOptions)
      .then(response => response.json())
      .then(data => {
        setState(prevState => ({ ...prevState, resources: data }));
        setState(prevState => ({ ...prevState, azureResourceAvailable: true }))
      });
  }

  const getRTFResource = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/mule/rtf', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log("RTF");
        console.log(data);
        setState(prevState => ({ ...prevState, availableRTF: data.mule }))
      });
  }

  const getGitRepos = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/repo', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data) {
          setState(prevState => ({ ...prevState, gitReposAvailable: true }))
        } else {
          return null;
        }


      });
  }

  const getJenkins = async () => {
    console.log(localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/jenkins', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setState(prevState => ({ ...prevState, jenkinsResourceAvailable: true }))
        } else {
          return null;
        }


      });
  }
  const getBitbucket = async () => {
    console.log(localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/bitbucket', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setState(prevState => ({ ...prevState, bitbucketResourceAvailable: true }))
        } else {
          return null;
        }


      });
  }
  const getMule = async () => {
    console.log(localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/mule', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log("anypointdata", data)
        if (data && data.mule.length) {
          setState(prevState => ({ ...prevState, anypointData: data }));
          setState(prevState => ({ ...prevState, muleResourceAvailable: true }))
        //   const reqOptions ={
        //     method:'GET',
        //     headers:{
        //     "Authorization" :'Basic ' + Buffer.from(data.muleUsername+ ':' + data.mulePassword).toString('base64'),
        //     "Content-Type": "application/json"}
        // }
        // fetch('https://anypoint.mulesoft.com/runtimefabric/api/organizations/'+data.muleOrgId+'/targets',reqOptions)
        // .then()
        } else {
          return null;
        }

      });
  }


  const checkAccessManagement = async () => {
    console.log(state.gitReposAvailable)
    if (!state.gitReposAvailable)
      return { val: false, message: "Github properties not updated" };
    if (state.projects.cloudType === 'azure') {
      if (!state.azureResourceAvailable)
        return { val: false, message: "No Azure resources found" };
    }
    if (state.projects.cloudType === 'jenkins') {
      if (!state.jenkinsResourceAvailable)
        return { val: false, message: "No jenkins resource found" };
    }
    if (state.projects.cloudType === 'bitbucket') {
      if (!state.bitbucketResourceAvailable)
        return { val: false, message: "No bitbucket settings found" };
    }
    if (state.projects.projectType === 'mule') {
      if (!state.muleResourceAvailable)
        return { val: false, message: "No mule settings found" };
    }
    return { val: true }
  }
  const submit = async (id, name) => {

    setState(prevState => ({ ...prevState, validationError: false }));
    setContent({ consoleMessage: 'Build in progress' })
    console.log("project", state.projects);
    handleClose();
    //JILTY 
    // var postObj =
    // {
    //   "projectName": state.projects.projectName,
    //   "gitRepo": state.gitRepo,
    //   "anypointData":state.anypointData.mule[0],
    //   "id": projId,
    //   "resourceGroupName": state.selected_rg,
    //   "gitType": state.projects.cloudType === "bitbucket" ? "bitbucket" : state.gitType,
    //   "deployType": state.deployType,
    //   "targetRTF": state.targetRTF ? state.targetRTF : null,
    //   "targetCluster": state.targetCluster ? state.targetCluster : null,
    //   "projectTemplate": state.projects.projectArchetype.replace('apix-', '').replace('-sapi','')

    // }

    var postObj =
    {
      "projectName": state.projects.projectName,
      "gitRepo": state.gitRepo,
      "anypointData": state.anypointOrg,
      "id": projId,
      "resourceGroupName": state.selected_rg,
      "gitType": state.projects.cloudType === "bitbucket" ? "bitbucket" : state.gitType,
      "gitUsername": state.gitUsername,
      "deployType": state.deployType,
      "targetRTF": state.targetRTF ? state.targetRTF : null,
      "targetCluster": state.targetCluster ? state.targetCluster : null,
      "workersNumber": state.workersNumber ? state.workersNumber : null,
      "workerType": state.workerType ? state.workerType : null,
      "workerSize": state.workerSize ? state.workerSize : null,
      "processType": state.projects.processType,
      "replicaSize": state.replicaSize ? state.replicaSize : null,
      "replicas": state.replicas ? state.replicas : null,
      "cloud2TargetId": state.deploymentTarget ? state.deploymentTarget : null,
      "message":localStorage.getItem('message'),
      "response":localStorage.getItem('chatResponse'),
      "messagefrom":"build",
      "ramlData":localStorage.getItem('ramlData'),
      "xmlData":localStorage.getItem('xmlData'),
      "dependencyData":localStorage.getItem('dependencyData'),
      "environment":state.environment ? state.environment : 'dev',


    }

    let checkVal = await checkAccessManagement();
    console.log("submitttttttttttttttttt",postObj);
    if (checkVal.val) {
      var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
        body: JSON.stringify(postObj)
      };
      // this.setState({ startedDeployment: true });
      setState(prevState => ({ ...prevState, buildStarted: true }))
      fetch(domain + '/api/v1/azprojectbuild?id=' + projId, requestOptions)
        .then(response => response.json())
        .then(data => {
          // console.log(data);
        });
    } else {
      setState(prevState => ({ ...prevState, validationError: true }));
      setState(prevState => ({ ...prevState, validationMessage: checkVal.message }))
    }


  }
  const deploy = () => {
    setContent({ consoleMessage: 'Deployment in progress' })
    resetState()
    setState(prevState => ({ ...prevState, startedDeployment: true }));
    clearConsole();
    console.log(state);
    var postObj =
    {
      "projectName": state.projects.projectName,
      "id": projId,
      "resourceGroupName": state.projects.resourceGroupName
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
      body: JSON.stringify(postObj)
    };
    setState(prevState => ({ ...prevState, startedDeployment: true }));
    fetch(domain + '/api/v1/deployapplication?id=' + projId, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
      });
  }
  const setContent = () => {
    console.log(true);
  }
  const handleOpen = () => {
    console.log('Show modal')
    setState(prevState => ({ ...prevState, showModal: true }))
  }
  const handleClose = () => {
    setState(prevState => ({ ...prevState, showModal: false }))
  }
  const handleResourceGroup = (event) => {
    console.log('inside resource')
    console.log(event.value)
    state.resources.map((res) => {
      console.log(res)
      if (res.resource_group_name == event.value) {
        var joined = state.azure_container.concat(res.azure_container_registry_name);
        console.log(joined)
        setState(prevState => ({ ...prevState, azure_container: joined }))
        console.log('inside condition');
        console.log(state)
      }
    })

  }
  function getContainerBody() {
    console.log('container body');
    return (
      <div>
        <div className="text-label-wrap">
          <label className="form-text-label">Select Container(required)</label>
        </div>
        <div className="text-input-wrap">
          <Select
            name='container'
            value={state.cloudType}
            onChange={onChange}
            options={state.azure_container}
            className="form-imput"
          />
        </div>

      </div>
    )

  }
  const setSelectProperties = (rg) => {
    state.resources.map(res => {
      console.log(res);
      console.log(rg);
      if (res.resource_group_name === rg) {
        console.log("inside");
        let container = state.azure_container.concat(res.azure_container_registry_name);
        setState(prevState => ({ ...prevState, azure_container: container }))
        console.log(state.azure_container);
      }
    })
  }
  const getMuleEnv = () => {
    return (
      <div>
       
      </div>
    )
  }
  const getModalBody = () => {

    switch (state.projectStep) {
      case 1:
        return (
          <div>
            <div>
              {
                state.projects && state.projects.projectType !== 'mule' ?
                  <div>
                    <div className="text-label-wrap">
                      <label className="form-text-label">Select Resource Group(required)</label>
                    </div>
                    <div className="text-input-wrap">
                      <select className="browser-default custom-select" name='selected_rg' onChange={onChange}>
                        <option>Resource Group</option>
                        {state.resource_group_name && state.resource_group_name.map(res => {
                          return (
                            <option value={res}>{res}</option>
                          )
                        })}
                      </select>
                    </div>
                   
                  </div> : null
              }

              {
                (state.projects && state.projects.cloudType) !== "bitbucket" ? <div>
                  <div className="text-label-wrap">
                    <label className="form-text-label">Repo type</label>
                  </div>
                  <div className="text-input-wrap">
                    <select className="browser-default custom-select" name='gitType' onChange={onChange}>
                      <option>Git Type </option>
                      {(state.projects && state.projects.projectType === 'azure') ? <option value="azure"> Azure </option> : null}

                      <option value="github"> Github </option>
                      <option value="bitbucket"> Bitbucket </option>
                      <option value="gitlab"> Gitlab </option>

                    </select>

                  </div>
                </div> : null
              }
              {
                state.gitType === 'github' ?
                  <div>
                    <div className="text-label-wrap">
                      <label className="form-text-label">GIT Username</label>
                    </div>
                    <div className="text-input-wrap">
                      <select className="browser-default custom-select" name='gitUsername' onChange={onChange}>
                        <option>Git Username </option>
                        {
                          state.github.map(git => {
                            return (
                              <option value={git.gitUsername}> {git.gitUsername} </option>
                            )

                          })
                        }

                      </select>

                    </div>
                  </div> : null
              }
              {
                (state.anypointData && state.anypointData.mule && state.projects.projectType) === "mule" ? <div>
                  <div className="text-label-wrap">
                    <label className="form-text-label">Anypoint Organisation</label>
                  </div>
                  <div className="text-input-wrap">
                    <select className="browser-default custom-select" name='anypointOrg' onChange={onChange}>
                      <option>Anypoint Organization Name </option>
                      {
                        state.anypointData.mule.map(mule => {
                          return (
                            <option value={mule.muleOrg}> {mule.muleOrg} </option>
                          )
                        })
                      }

                    </select>


                  </div>
                   <div>
                <div className="text-label-wrap">
                  <label className="form-text-label">Deployment Method</label>
                </div>
                <div className="text-input-wrap">
                  <select className="browser-default custom-select" name='deployType' onChange={onChange}>

                    <option>Deployment Type </option>

                    <option value="rtf"> RTF </option>
                    <option value="cloudhub"> Cloudhub </option>
                    <option value="cloudhub2.0"> CloudHub2.0 </option>

                  </select>

                </div>
              </div>
                </div> : null
              }
             
              {
                state.deployType === 'rtf' ?
                  <div>
                    <div className="text-label-wrap">
                      <label className="form-text-label">RTF</label>
                    </div>
                    <div className="text-input-wrap">
                      <select className="browser-default custom-select" name='targetRTF' onChange={onChange}>
                        <option>RTF NAME </option>

                        {state.availableRTF && state.availableRTF.map(rtf => {
                          return (
                            rtf.status === 'Active' ?
                              <option value={rtf.rtfName}> {rtf.rtfName} </option> : null
                          )
                        })}

                      </select>

                    </div>
                    {/* <div>
                <div className="text-label-wrap">
                  <label className="form-text-label">Cluster</label>
                </div>
                <div className="text-input-wrap">
                  <select className="browser-default custom-select" onChange={(event) => { this.setState({ targetCluster: event.target.value }) }}>
                    <option>Cluster NAME </option>
                  
                   {this.state.avaialableRTF && this.state.aksClusterResouce.map(cluster =>{
                      return(
                        <option value={cluster.aks_cluster_name}> {cluster.aks_cluster_name} </option>
                      )
                    })}

                  </select>

                </div>
              </div> */}
                  </div>
                  : null
              }
              {
                state.deployType === 'cloudhub' ?
                  <div>

                    <div className="text-input-wrap">
                      {
                        cloudHubDeployList.map(res => {
                          return (
                            <div className="form-content">
                              <AddNewInput res={res} onChange={onChange} />

                            </div>
                          )
                        })
                      }

                    </div>

                  </div>
                  : state.deployType === 'cloudhub2.0' ?
                    <div>

                      <div className="text-input-wrap">
                        {
                          cloudHub2DeployList.map(res => {
                            return (
                              <div className="form-content">
                                <AddNewInput res={res} onChange={onChange} />
                              </div>
                            )
                          })
                        }
                        <div className="text-label-wrap">
                          <label className="form-text-label">Deployment Target</label>
                        </div>
                        <div className="text-input-wrap">
                          <select className="browser-default custom-select" name='deploymentTarget' onChange={onChange}>
                            <option>Deployment Target </option>

                            {state.anypointData.mule.filter(item => item.muleOrg === state.anypointOrg)[0].cloud2TargetsArray.map(target => {

                              return (
                                <option value={target.id}> {target.name} ({target.type}) </option>
                              )
                            })
                            }

                          </select>
                        </div>
                      </div>
                    </div>
                    : null
              }

              {state.projects && state.projects.projectType === 'mule' ? getMuleEnv() : null}
            </div>
            {/* {this.state.akscontainer && this.state.akscontainer.length ? this.getContainerBody() : <div></div>} */}

          </div>

        )
      case 2:
        return (<div>
          <div>
            <div className="text-label-wrap">
              <label className="form-text-label">Github Repo (required)</label>
            </div>
            <div className="text-input-wrap">
              <select className="browser-default custom-select" name='gitRepo' onChange={onChange}>
                <option>Git Repo</option>
                {state.github && state.github.map(res => {
                  return (
                    <option value={res.gitRepo}>{res.gitRepo}</option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        )

      default: break;
    }
  }
  const next = () => {
    let stateTemp = state.projectStep;
    setState(prevState => ({ ...prevState, projectStep: stateTemp + 1 }))
  }
  const prev = () => {
    let stateTemp = state.projectStep;
    setState({ projectStep: stateTemp - 1 })
  }
  function checkBuildSuccess() {
    console.log(state);
    var postObj =
    {
      "projectName": state.projects.projectName,
      "id": projId
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
      body: JSON.stringify(postObj)
    };
    setState(prevState => ({ ...prevState, startedDeployment: true }));
    fetch(domain + '/api/get/buildstatus', requestOptions)
      .then(response => response.json())
      .then(data => {
        return data
      });
  }
  function checkBuildStatus() {
    console.log('eewew')
    let data = checkBuildSuccess();
    if (data && data.message == 'completed') {
      console.log('completed');
      return false;
    }
    else {
      return true;
    }

  }
  function showConsoleOutput() {
    if (state.myclass === '') {
      setState(prevState => ({ ...prevState, myclass: 'show' }))
    }
    else {
      setState(prevState => ({ ...prevState, myclass: '', }))
    }
  }
  const clearConsole = () => {
    setState(prevState => ({ ...prevState, consoleValue: "" }))
  }
  function getStatusElement() {
    let statusData =[]
    console.log("state.statusTempSelect",state.statusTempSelect)
    console.log("state.projTempSelect",state.projTempSelect)

    
    if(state.statusTempSelect === true){  
      statusData = (projectStatus !== undefined && projectStatus.length !== 0)? projectStatus.filter(status => {

    return status.exactPjtName === state.projTempSelect;
  }): [];
  }
  console.log("STATUS DATA",statusData)
    let statusBody = null;
    if (projectStatus && projectStatus.length == 0 && statusData.length == 0 ) {
      statusBody = (<div></div>)
    } else if(state.statusTempSelect === false) {

      statusBody = projectStatus && projectStatus.length && projectStatus.map((stat, i) =>
        <Col xs={12} className="status-body">
          {
            <Row className={"completed-status " + stat.status}>
              <Col xs={2}>
                {stat.status === 'completed' ? <StatusIcon icon={completedIcon} ></StatusIcon> : <StatusIcon icon={progressIcon} ></StatusIcon>}
              </Col>
              <Col xs={10}>
                <div className="status-label">
                  {stat.message}
                </div>
              </Col>
            </Row>
          }
        </Col>

      )
    }else {

      // statusBody = state.projTempStatus && state.projTempStatus.length && state.projTempStatus.map((stat, i) =>
      statusBody = statusData && statusData.length && statusData.map((stat, i) =>

        <Col xs={12} className="status-body">
          {
            <Row className={"completed-status " + stat.status}>
              <Col xs={2}>
                {stat.status === 'completed' ? <StatusIcon icon={completedIcon} ></StatusIcon> : <StatusIcon icon={progressIcon} ></StatusIcon>}
              </Col>
              <Col xs={10}>
                <div className="status-label">
                  {stat.message}
                </div>
              </Col>
            </Row>
          }
        </Col>

      )

    }
    return statusBody;
  }
  function getConsoleHeader() {
    let header = "";
    if (state.buildStarted) {
      header = (<span className="console-header inprogress">Build in-progress</span>)
    } else if (state.readyToDeploy) {
      header = (<span className="console-header complete">Build Complete</span>)
    } else if (state.startedDeployment) {
      header = (<span className="console-header inprogress">Started Deployment</span>)
    } else if (state.deployed) {
      header = (<span className="console-header deployed">Deployed</span>)
    } else {
      return null;
    }
    return header;
  }
  const sidemenuList = ['Azure', 'Github', 'Settings']
 console.log("STATEEEEEE",state)
  return (
    <div className="root" >
      <CustomHeader></CustomHeader>
      <Container className="content project-content">
        <div >

          {state.projects ?
            <div>
              {
                state.validationError ?
                  <Row className="error-label-wrap">
                    <label>
                      {state.validationMessage} <a href="/settings">Go to settings</a>
                    </label>
                  </Row> : null
              }

              <Row className="project-main-row">

                <Col xs={8}>

                  <Row className="application-details-header">
                    <Col xs={3}>
                      <h3 className="project-centre-header">Application Details</h3>
                    </Col>
                    <Col xs={9} className="">
                      <button className='create-project-btn' variant="contained" onClick={() => handleOpen()}>

                        {state.projects.projectType === 'mule' ? 'Create Build and Release' : 'Build'}
                      </button>
                      {state.projects && state.projects.projectType !== 'mule' ? <button className="create-project-btn" onClick={() => deploy()}>Deploy</button> : null}

                      {/* {this.state.projects.cloudType !== 'jenkins' ? <button className="create-project-btn" onClick={() => this.createRelease()}>Create Release</button> : null} */}

                      {/* {this.state.readyToDeploy ?
                          <Button className="create-project-btn" disabled={!this.state.readyToDeploy} onClick={() => this.deploy()}>Deploy</Button>
                          : null} */}

                    </Col>
                  </Row>


                  <div style={{ 'height': '500px', 'overflow-y': 'scroll' }} className="project-details-wrap">

                    {
                      state.projects.processType === 'single' ?
                        <>
                          <Chart theme="dark" chartData={{ "Project Name": state.projects.projectName, "Repo": "https://github.com/istikanjc/" +state.projects.projectName, "CI/CD Server": state.projects.cloudType, "Project Type": state.projects.projectType }} gridval="6">

                          </Chart>
                        </> : state.projects.projectArchetype === 'apix-mule-multi-tier' ?
                          multipleProjectTemplate.map(projName => {
                            let chartData = { "Project Name": state.projects.projectName + '-' + projName, "Repo": "https://github/" + state.github.gitUsername + '/' + state.projects.projectName + '-' + projName, "CI/CD Server": state.projects.cloudType, "Project Type": state.projects.projectType }
                            return (<Chart chartData={chartData} gridval="12" theme="dark" ></Chart>)
                          }): state.projects.projectArchetype === 'apix-education-multi-tier' ?
                          <>
                          <MultiChart container="System APIs" Templates={eduPjtSapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}  gridval="6" theme="dark" ></MultiChart>
                          <MultiChart container="Process APIs"  Templates={eduPjtPapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}   gridval="6" theme="dark" ></MultiChart>
                          <MultiChart container="Experience APIs"  Templates={eduPjtEapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}  gridval="6" theme="dark" ></MultiChart>
                          </>
                          // educationProjectTemplate.map(projName => {
                          //   let chartData = { "Project Name": state.projects.projectName + '-' + projName, "Repo": "https://github/" + state.github.gitUsername + '/' + state.projects.projectName + '-' + projName, "CI/CD Server": state.projects.cloudType, "Project Type": state.projects.projectType }
                          //   return (<Chart chartData={chartData} gridval="12" theme="dark" ></Chart>)
                          //}) 
                          : state.projects.projectArchetype === 'open-finance-multi-tier' ?
                          <>                         
                            <MultiChart container="System APIs" Templates={openFinProjectSapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}  gridval="6" theme="dark" ></MultiChart>
                            <MultiChart container="Process APIs"  Templates={openFinProjectPapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}   gridval="6" theme="dark" ></MultiChart>
                            <MultiChart container="Experience APIs"  Templates={openFinProjectEapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}  gridval="6" theme="dark" ></MultiChart>
                            </>  :state.projects.projectArchetype === 'apix-banking-multi-tier' ?
                          <>  
                           <MultiChart container="System APIs" Templates={openBankingPjtSapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}  gridval="6" theme="dark" ></MultiChart>
                            <MultiChart container="Process APIs"  Templates={openBankingPjtPapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}   gridval="6" theme="dark" ></MultiChart>
                            <MultiChart container="Experience APIs"  Templates={openBankingPjtEapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}  gridval="6" theme="dark" ></MultiChart>                       
                            </>:state.projects.projectArchetype === 'api-general-apis' ?
                            <>
                             <MultiChart container="Process APIs"  Templates={generalPjtPapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}   gridval="6" theme="dark" ></MultiChart>
                             <MultiChart container="Experience APIs" Templates={generalPjtEapi} status={projectStatus} projectName={state.projects.projectName} getProjectStatus={getProjectStatus}  gridval="6" theme="dark" ></MultiChart>
                             </>:
                             <></>
 
                    }
                  </div>



                </Col>
                <Col xs={4} className="project-side-desc-wrap">
                  <Row>
                    <Col xs={4}>
                      <h3>Status</h3>
                    </Col>

                  </Row>

                  <div className="status-wrap">
                    <Row className="full-width status-div">

                      <Col xs={12}>
                        {getStatusElement()}
                      </Col>
                    </Row>
                  </div>

                </Col>

              </Row>

              <div id="box-console" className={'box box-console ' + state.myclass}>
                <div className="editor-resizer editor-resizer-console" title="Drag to resize. Double-click to expand."></div>
                <div className="powers">
                  <div className="powers-drag-handle" title="Drag to resize. Double-click to expand."></div>
                  <div className="editor-actions-left">
                    <h2 className="box-title"><span className="box-title-name">Console</span> <span>{consoleMessages}</span></h2>
                  </div>
                  <div className="editor-actions-right">
                    <button className="button button-medium mini-button console-clear-button" title="Clear" onClick={() => clearConsole()}>
                      Clear
                    </button>
                    {/* <button className="button button-medium mini-button close-editor-button" data-type="console" title="Close">

                      </button> */}
                  </div>
                </div>
                <div className="console-wrap">
                  <div className="console-entries short-no-scroll">
                    <div className="console-command-line">
                      <span className="console-arrow forwards"></span>
                      <textarea className="console-command-line-input auto-expand" rows="1" data-min-rows="1" value={consoleMessages}>

                      </textarea>
                      {getConsoleHeader()}

                    </div>
                  </div>
                  {/* <div className="console-command-line">
                      <span className="console-arrow forwards"></span>
                      <textarea className="console-command-line-input auto-expand" rows="1" data-min-rows="1" >

                      </textarea>
                    </div> */}
                </div>
              </div>


              {/* <Row className="console-output">
                <div className="conole-outpur-header header" onClick={() => this.showConsoleOutput()}>...</div>
                <Row className={'console-output-container ' + this.state.myclass}>
                  {this.getStatusElement()}
                </Row>
              </Row> */}
            </div>
            : <div></div>}
          <Modal show={state.showModal} onHide={() => handleClose()}>
            <Modal.Header closeButton className="modal-header">
              <Modal.Title>Project Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {getModalBody()}
              </div></Modal.Body>
            <Modal.Footer className="modal-footer">
              <Button variant="secondary" onClick={() => handleClose()}>
                Cancel
              </Button>

              <Button variant="primary" onClick={() => submit()}>
                Ok
              </Button>


            </Modal.Footer>
          </Modal>

        </div>

      </Container >
      <footer id="react-pen-footer" className="site-footer editor-footer">
        <div className="footer-left">
          <div className="footer-actions-menu">

            <div className="footer-actions" data-expanded="false">
              <button className="button footer-button console-toggle-button" onClick={() => showConsoleOutput()}>Console</button>
            </div>

          </div>
        </div>
      </footer>
    </div >
  );
}


