import azureIcon from '../../src/assets/icons/azure-new.png';
import springIcon from '../../src/assets/icons/spring-new.png'
import reactIcon from '../../src/assets/icons/react-new.png'
import awsIcon from '../../src/assets/icons/aws.png'
import bitbucket from '../../src/assets/icons/bitbucket.png'
import mulesoftIcon from '../../src/assets/icons/MuleSoft-logo.png'
import golangIcon from '../../src/assets/icons/golang.png'
import jenkinsIcon from '../../src/assets/icons/jenkins.png'
import rtfIcon from '../../src/assets/icons/rtf.png'
import servicenowicon from '../../src/assets/icons/Servicenow-logo.png';
import salesforceicon from '../../src/assets/icons/SalesForce-logo.png';
import genericdbicon from '../../src/assets/icons/SQL-logo.png';
import jmetericon from '../../src/assets/icons/JMeter-logo.png';
import anaplan from '../../src/assets/icons/Anaplan-logo.png';
import workday from '../../src/assets/icons/Workday-logo.png';
import kafka from '../../src/assets/icons/Kafka-logo.png';
import magento from '../../src/assets/icons/Magento-logo.png';
import lamda from '../../src/assets/icons/AWS-Lambda-logo.png';
import mongodb from '../../src/assets/icons/MongoDB-logo.png';
import sitecore from '../../src/assets/icons/sitecore.png';
import mews from '../../src/assets/icons/Mews-logo.png';
import educationIcon from '../../src/assets/icons/education.png';
import bankIcon from '../../src/assets/icons/bank.png';
import wealthIcon from '../../src/assets/icons/wealth-logo.jpg'
import congaIcon from '../../src/assets/icons/conga.png';
import contentHubIcon from '../../src/assets/icons/site-contenthub.png'
import transactisIcon from '../../src/assets/icons/transactis.png'
import metricstoolkit from '../../src/assets/icons/metrics-toolkit.png'
import netsuiteIcon from '../../src/assets/icons/netsuit.png'
import amadeusIcon from '../../src/assets/icons/amadeus.png';
import coupaIcon from '../../src/assets/icons/coupa.png'
import odooIcon from '../../src/assets/icons/odoo.png'
import nexioIcon from '../../src/assets/icons/nexio.png'
import aiIcon from '../../src/assets/icons/ai.png'
import generalapi from '../../src/assets/icons/generalapi.png'



export const techStack= [
    {
      "name": "spring",
      "description": "Springboot template",
      "selected":false,
      "icon":springIcon
    },
     {
      "name": "react",
      "description": "React template",
       "selected":false,
      "icon":reactIcon
    }, {
      "name": "mule",
      "description": "Mule template",
       "selected":false,
      "icon":mulesoftIcon
    }, {
      "name": "go Lang",
      "description": "Go template",
       "selected":false,
      "icon":golangIcon
    }
  ]
export const cloudStack= [
    {
      "name": "azure",
      "description": "Azure Cloud",
      "selected":false,
      "icon":azureIcon
    },
     {
      "name": "aws",
      "description": "Aws Cloud",
      "icon":awsIcon
    },
    {
      "name": "bitbucket",
      "description": "Bit Bucket",
      "icon":bitbucket
    },
    {
      "name": "jenkins",
      "description": "Jenkins template",
       "selected":false,
      "icon":jenkinsIcon
    }
  ]
export const archetypesStack=[
// {
//   name: "Mule Placeholder Template",
//   value: "apix-mule-placeholder",
//   description: "",
//   icon: mulesoftIcon
// },
{
  name: "AI Generated",
  value: "ai-template",
  description: "",
  icon: aiIcon
},
{
  name: "General Process & Experience API",
  value: "api-general-apis",
  description: "",
  icon: generalapi
},


{
  name: "Generic Datbase",
  value: "api-generic-db-sapi",
  description: "",
  icon: genericdbicon
},
{
  name: "Salesforce",
  value: "api-salesforce-sapi",
  description: "",
  icon: salesforceicon
},
{
  name: "Servicenow",
  value: "api-servicenow-sapi",
  description: "",
  icon: servicenowicon
},
// {
//   name: "Performance",
//   value: "apix-jmeter-automation-master",
//   description: "",
//   icon: jmetericon
// },
{
  name: "Anaplan",
  value: "api-anaplan-sapi",
  description: "",
  icon: anaplan
},
// {
//   name: "Kafka",
//   value: "apix-kafka-sapi",
//   description: "",
//   icon: kafka
// },
{
  name: "Magento",
  value: "api-magento-sapi",
  description: "",
  icon: magento
},{
  name: "Workday",
  value: "api-workday-sapi",
  description: "",
  icon: workday
},
{
  name: "Mews",
  value: "api-mews-sapi",
  description: "",
  icon: mews
},
{
  name: "Lambda",
  value: "apix-lambda-sapi",
  description: "",
  icon: lamda
},
{
  name: "Mongodb",
  value: "api-mongodb-sapi",
  description: "",
  icon: mongodb
},
{
  name: "Sitecore",
  value: "apix-sitecore-sapi",
  description: "",
  icon: sitecore
},
{
  name: "Salesforce composite",
  value: "api-salesforce-composite-sapi",
  description: "",
  icon: salesforceicon
},
{
  name: "Conga",
  value: "api-conga-sapi",
  description: "",
  icon: congaIcon
},
{
  name: "Transactis",
  value: "api-transactis-sapi",
  description: "",
  icon: transactisIcon
},
{
  name: "ContentHub",
  value: "api-content-hub-sapi",
  description: "",
  icon: contentHubIcon
},
{
  name: "Coupa",
  value: "api-coupa-sapi",
  description: "",
  icon: coupaIcon
},
{
  name: "Nexio",
  value: "api-nexio-sapi",
  description: "",
  icon: nexioIcon
},
{
  name: "Amadeus",
  value: "api-amadeus-sapi",
  description: "",
  icon: amadeusIcon
},
{
  name: "Netsuite",
  value: "api-netsuite-sapi",
  description: "",
  icon: netsuiteIcon
},
{
  name: "Odoo",
  value: "api-odoo-sapi",
  description: "",
  icon: odooIcon
},
{
  name: "Education acceleator",
  value: "apix-education-multi-tier",
  description: "",
  icon: educationIcon
},
{
  name: "Open Banking Accelerator",
  value: "apix-banking-multi-tier",
  description: "",
  icon: bankIcon
},
{
  name: "Open Finance Accelerator",
  value: "open-finance-multi-tier",
  description: "",
  icon: wealthIcon
},{
  name: "Multi-tier Service Cloud acceleator",
  value: "apix-mule-multi-tier",
  description: "",
  icon: mulesoftIcon
}

// {
//   name: "Mulesoft metrics toolkit",
//   value: "apix-metrics-toolkit",
//   description: "",
//   icon: metricstoolkit
// },


]